import { useEffect } from 'react';
import './App.css';
import { getCsurf } from './config/axios.request';
import { EndPoint } from './routes/routes';
function App() {
  return (
      <>
          <EndPoint />
      </>
  );
}

export default App;
