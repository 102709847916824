import React from "react";
import { Form , Row } from "react-bootstrap";

export const PasswordField = ({ onChange , passErr }) => {
  return (
    <>
      <Row className="mb-3">
        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label className="instruction">{passErr}</Form.Label><br/>
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            placeholder="Entrez votre mot de passe"
            type="password"
            required
            onChange={onChange}
          />
        </Form.Group>
      </Row>
    </>
  );
};
