import React from "react";
import { Button , Alert} from "react-bootstrap";

export const SuccessLogIn = ({show,iShow})=>{
    return(
        <>
            {show && (
                <div className="signin-success">
                    <Alert variant='success'>
                    <Alert.Heading>Connexion réussie ! </Alert.Heading>
                    <p>Bienvenue à nouveau  ensemble codons le futur !  </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={iShow} variant="outline-success">
                        Fermer
                    </Button>
                    </div>
                 </Alert>
               </div>
            )}
        </>
    )
}