import React from "react";
import { Button , Alert} from "react-bootstrap";

export const SuccessSignIn = ({show,onClick})=>{
    return(
        <>
            {show && (
                <div className="signin-success">
                    <Alert variant='success'>
                    <Alert.Heading>Inscription réussie ! </Alert.Heading>
                    <p>Maintenant connectez-vous </p>
                    <div className="d-flex justify-content-end">
                    <Button onClick={onClick} variant="outline-success">
                       Cliquez ici
                    </Button>
                    </div>
                 </Alert>
               </div>
            )}
        </>
    )
}