import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { OtpSubmit } from "./otp-submit";
import axios from "axios";

export const NewPasswordField = ({ showed }) => {
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("dataUsers");
    setData((user ??= null));
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const char = /[a-zA-Z]/
    if(!char.test(otp) &&  otp.length == 6){
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/api/submit-otp`,
        {
          otp: otp,
          password: password,
          data: data,
        },
        {
          withCredentials: true,
          withXSRFToken: true,
        }
      )
      .then((res) => window.location.href=`${process.env.REACT_APP_API_URL}/success-reset`)
      .catch((err) =>  window.location.href=`${process.env.REACT_APP_API_URL}/failed-reset`);
    }else{
      alert('Otp incorrect !')
    }
  };
  return (
    <>
      {showed && (
        <Form className="form-login">
          <OtpSubmit onChange={(e) => setOtp(e.target.value)} />
          <div className="services-card">
            <span> ................. </span>
            <h3 style={{ textAlign: "center" }}>
              Saisissez un nouveau mot de passe !
            </h3>
            <span> ................. </span>
          </div>
          <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label>Nouveau mot de passe</Form.Label>
            <Form.Control
              placeholder="Entrez un nouveau mot de passe"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" id="submit-btn" onClick={handleSubmit}>
            Changer de mot de passe
          </Button>
          <div className="other-link-2">
            <a className="a">Politique de confidentialité</a>
            <a className="a"></a>
          </div>
        </Form>
      )}
    </>
  );
};
