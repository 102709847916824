import React from "react";
import { Alert  , Button} from "react-bootstrap";

export const Success_Reset = ({onClick})=>{
    return(
        <>
            <div className="signin-success">
                <Alert variant='success'>
                <Alert.Heading>Operation réussie ! </Alert.Heading>
                <p>Maintenant connectez-vous avec votre nouveau mot de passe </p>
                <div className="d-flex justify-content-end">
                <Button onClick={onClick} variant="outline-success">
                   Cliquez ici
                </Button>
                </div>
             </Alert>
            </div>
        </>
    )
}