import React from "react";
import { Button, Form } from "react-bootstrap";

export const OtpSubmit = ({ onChange}) => {
  return (
    <>
        <h5 style={{ textAlign: "center" }}>
          Saisissez le code que nous vous avons envoyé !
        </h5>
        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label>Saisissez le code OTP</Form.Label>
          <Form.Control
            placeholder="Entrez le code otp"
            type="text"
            required
            onChange={onChange}
          />
        </Form.Group>
    </>
  );
}

