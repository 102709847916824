const char = /[a-zA-Z]/
const int   = /[0-9]/
const CharSpc = /[!*&()+_|?/'"~;@#$%^m,`.]/;

export const isValidateUsername = (username) =>{
    if(!int.test(username) && char.test(username) && username.length >= 3) 
        return true;
    else  return false;
}

export const  isValidatePassword = (password)=>{
    if(CharSpc.test(password) && char.test(password) && password.length >=6) 
        return '';
    else return  'Le mot de passe doit contenir au moins un caractère spécial comme:  [!*&()+_|?/"~;@#$%^m,`.]' 
}
export const isValidateNumber = (number)=>{
    if(!char.test(parseInt(number)) && !CharSpc.test(number)  && number.length >=6 && number.length <= 14) 
        return '';
    else return  'Veuillez verifier si vous avez bien saisi le numéro ';
}

export const isPassCompareSuccess = (password,passToCompare) =>{
    if(password == passToCompare && password.length >= 6) 
        return '';
    else return 'Mot de passe non conformes';
}