import React from "react";
import { Alert , Button} from "react-bootstrap";

export const Failed_Reset = () => {
  return (
    <>
        <div className="signin-success">
          <Alert variant="danger">
            <Alert.Heading>Operation echouée ! </Alert.Heading>
            <p>
              Verifiez si le code que vous avez saisi est correcte ou si
              l'adresse mail est bien associée à un compte ou si le mot de passe
              est bien saisi{" "}
            </p>
            <div className="d-flex justify-content-end">
              <Button onClick={()=>window.location.href=`${process.env.REACT_APP_API_URL}/reset-password`} variant='danger'>
                Cliquez ici
              </Button>
            </div>
          </Alert>
        </div>
    </>
  );
};
