import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { UsernameField } from "./form-fields/username.field";
import { NumberField } from "./form-fields/number.field";
import { EmailField } from "./form-fields/email.field";
import { PasswordField } from "./form-fields/password.field";
import { SignSpinner } from "./sign-spinner";
import {
  isPassCompareSuccess,
  isValidateNumber,
  isValidatePassword,
} from "./form-validation/signIn.validation";
import { submitData } from "./axios/axios-signin";
import { SuccessSignIn } from "./success-messages/signIn-success";

export const SignIn = () => {
  const [display, setDisplay] = useState({
    show: false,
    isSpinner: false,
    form: true,
    success: false,
  });
  const [errors, setErrors] = useState({
    passErr: "",
    numErr: "",
    confirmPassErr: "",
    existMailErr: "",
  });
  const [user, setUser] = useState({
    username: "",
    number: "",
    email: "",
    password: "",
    passConfirm: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      ...errors,
      passErr: isValidatePassword(user.password),
      confirmPassErr: isPassCompareSuccess(user.password, user.passConfirm),
      numErr: isValidateNumber(user.number),
    });

    if (((errors.passErr == errors.confirmPassErr) == errors.numErr) == "") {
      setDisplay({ ...display, isSpinner: true, form: false });
      try {
        setDisplay({ ...display, isSpinner: true });
        const response = await submitData(user);
        //
        setDisplay({ ...display, isSpinner: false });
        //
        if (response === "success") {
          setDisplay({
            ...display,
            success: true,
            form: false,
          });
        } else if (response.includes("mail")) {
          setDisplay({
            ...display,
            form: true,
          });
          setErrors({ ...errors, existMailErr: "Ce mail existe déjà" });
        }
      } catch (error) {
        alert("Une erreur est survenue, veuillez réessayer.");
      }
    }
  };
  return (
    <>
      {display.form && !display.isSpinner && (
        <>
          <h4 style={{ textAlign: "center", margin: "5px" }}>
            Créez votre compte en quelques clics !
          </h4>
          <Form className="form" onSubmit={handleSubmit}>
            <UsernameField
              onChange={(e) => setUser({ ...user, username: e.target.value })}
            />
            <NumberField
              onChange={(e) => setUser({ ...user, number: e.target.value })}
              numErr={errors.numErr}
            />
            {errors.existMailErr && (
              <h4 style={{ color: "red", textShadow: "1px 1px 1px black" }}>
                Ce mail existe dèja
              </h4>
            )}
            <EmailField
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <PasswordField
              password={user.password.length}
              passErr={errors.passErr}
              onChangePass={(e) =>
                setUser({ ...user, password: e.target.value })
              }
              onChangePassConfirm={(e) =>
                setUser({ ...user, passConfirm: e.target.value })
              }
              confirmErr={errors.confirmPassErr}
            />
            <Button id="submit-btn" type="submit">
              Créez mon compte
            </Button>
          </Form>
        </>
      )}
      <SignSpinner isShow={display.isSpinner} />
      <SuccessSignIn
        show={display.success}
        onClick={() => (window.location.href = `${process.env.REACT_APP_API_URL}/login`)} 
      />
    </>
  );
};
