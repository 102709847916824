import axios from "axios";

export const submitData = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACK}/api/signin`,
      data: data,
      withCredentials: true,
      withXSRFToken: true,
    });

    if (response.status === 200) return "success";
    else return response.data?.Message || "Erreur inattendue.";
  } catch (error) {
    console.log(error)
    if (error.response) {
      return error.response.data?.Message || "Erreur du serveur.";
    }
    return "Erreur réseau.";
  }
};
