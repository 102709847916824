import React from "react";
import { Form ,Col , Row} from "react-bootstrap";

export const UsernameField = ({onChange}) => {
  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail" className="form-group">
        <Form.Label>Prenom && Nom</Form.Label>
          <Form.Control
            type="text"
            placeholder="Prenom & nom"
            required
            onChange={onChange}
            minLength={"4"}
          />
        </Form.Group>
      </Row>
    </>
  );
};
