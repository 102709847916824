import React, { useState , useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";

export const ActivateOtp = () => {
  const [otp, setOtp] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("dataUsers");
    setData((user ??= null));
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const char = /[a-zA-Z]/
    if(!char.test(otp) &&  otp.length == 6){
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/api/activate-account`,
        {
          otp: otp,
          data: data,
        },
        {
          withCredentials: true,
          withXSRFToken: true,
        }
      )
      .then((res) => {
        alert('Votre compte est actif maintenant connectez-vous')      
        setTimeout(()=>{
          window.location.href=`${process.env.REACT_APP_API_URL}/login`
        },3000)  
      })
      .catch((err) => alert('Une erreur est survenue ressayez plus tard'));
    }else{
      alert('Otp incorrect !')
    }
  };
  return (
    <>
      <h5 style={{ textAlign: "center" }}>
        Saisissez le code que nous vous avons envoyé !
      </h5>
      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Saisissez le code OTP</Form.Label>
        <Form.Control placeholder="Entrez le code otp" type="text" onChange={(e)=>setOtp(e.target.value)} required />
      </Form.Group>
      <Button id="submit-btn" onClick={handleSubmit}>Valider un code</Button>
    </>
  );
};
