import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { putItem } from "../storage/put-item";
import { ActivateOtp } from "./otp";

export const ActivateAccount = () => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACK}/api/reset-password`,
      data: {
        email: email,
      },
      withCredentials: true,
      withXSRFToken: true,
    })
      .then((res) => {
        setShow(true);
        putItem({
          passReset: {
            otp: (res.data.otp ??= null),
            email: (res.data.user ??= null),
          },
        });
      })
      .catch((err) => {
        setShow(false);
        alert("Une erreur est survenue !");
      });
  };
  return (
    <>
      <Form className="form-login">
        {!show && (
          <>
            <h3 style={{ textAlign: "center" }}>
              Saisissez votre adresse mail !
            </h3>
            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Adresse mail</Form.Label>
              <Form.Control
                placeholder="Entrez votre adresse mail"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              id="submit-btn"
              onClick={handleSubmit}
              style={{ margin: "10px" }}
            >
              Recevoir un nouveau code
            </Button>
            <Button id="submit-btn" onClick={() => setShow(true)}>
              Valider un code
            </Button>
            <div className="other-link-2">
              <a className="a">Politique de confidentialité</a>
              <a className="a"></a>
            </div>
          </>
        )}
        {show && <ActivateOtp />}
      </Form>
    </>
  );
};
