import React from "react";
import { Alert, Button } from "react-bootstrap";

export const Failed_Login = () => {
  return (
    <>
      <div className="signin-success">
        <Alert variant="danger">
          <Alert.Heading>Operation echouée ! </Alert.Heading>
          <p>
            Votre adresse mail n'est pas verifier et vous devez le faire
            immédiatement sinon si quelqu'un d'autre verifie ce mail sur son
            téléphone vous ne pourrez plus accéder à ce compte{" "}
          </p>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/activate-account`)
              }
              variant="danger"
            >
              Cliquez ici
            </Button>
          </div>
        </Alert>
      </div>
    </>
  );
};
