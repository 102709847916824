import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SignIn } from "../components/signIn-form";
import { LogIn } from "../components/login";
import axios from "axios";
import { PasswordReset } from "../components/password-reset/password-reset";
import { Failed_Reset } from "../components/success-messages/failed";
import { Success_Reset } from "../components/success-messages/success-reset";
import { ActivateAccount } from "../components/activate/active";
import { Failed_Login } from "../components/success-messages/activate.error";

export const EndPoint = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<SignIn />} path="*" />{" "}
          <Route element={<PasswordReset />} path="/reset-password"/>
          <Route element={<ActivateAccount />} path="/activate-account"/>

          {/*Page de  Creation de compte*/}
          <Route element={<LogIn />} path="/login" /> {/*Page de  Connexion*/}
          <Route element={<Failed_Reset />} path="/failed-reset" />
          <Route element={<Failed_Login />} path="/failed-login" />

          <Route element={<Success_Reset />} path="/success-reset" />
        </Routes>
      </Router>
    </>
  );
};
