import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";

export const PasswordField = ({
  onChangePass,
  onChangePassConfirm,
  passErr,
  confirmErr,
  password
}) => {
  return (
    <>
      <Row className="mb-3">
        <Form.Group className="mb-3" controlId="formGridAddress2">
          { password > 0 && (
            <Form.Label className="instruction">
              Le mot de passe doit composé minimum 6 caractères et au moins un
              caractère spécial (!*&()+_|?/'"~;@#$%^m,`.)
            </Form.Label>
          )}

          <Form.Label>Mot de passe</Form.Label>
          <Form.Label className="instruction">{passErr}</Form.Label>
          <Form.Control
            placeholder="Entrez  un  mot de passe"
            type="password"
            minLength={"6"}
            onChange={onChangePass}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGridAddress2">
          <Form.Label>Confirmez votre mot de passe</Form.Label>
          <Form.Control
            placeholder="Confirmez votre mot de passe"
            type="password"
            required
            onChange={onChangePassConfirm}
          />
          <Form.Label className="instruction">
            {confirmErr}
          </Form.Label>
        </Form.Group>
      </Row>
    </>
  );
};
