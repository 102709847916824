import React from "react";
import { Spinner } from "react-bootstrap";
export const SignSpinner = ({ isShow }) => {
  return (
    <>
      {isShow && (
        <div className="spinner">
          <h2>Création de votre compte en cours...</h2>
          <Spinner animation="border" role="status" />
        </div>
      )}
    </>
  );
};
