import React from "react";
import { Form , Row} from "react-bootstrap";

export const NumberField = ({ onChange , numErr }) => {
  return (
    <>
      <Row className="mb-3">
      <Form.Group className="mb-3" controlId="formGridAddress1" id="number">
      <Form.Label className="instruction">{numErr}</Form.Label><br/>
        <Form.Label>Numéro</Form.Label>
        <Form.Control
        placeholder="Entrez votre numéro"
        minLength={"9"}
        maxLength={"14"}
        required  
        onChange={onChange}
        />
        </Form.Group>
      </Row>
    </>
  );
};
