import React from "react";
import { Form } from "react-bootstrap";

export const EmailField = ({ onChange , mailErr}) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label style={{color:'red'}}>{mailErr}</Form.Label><br/>
        <Form.Label >Adresse mail</Form.Label>
        <Form.Control
          placeholder="Entrez votre adresse mail"
          type="email"
          required
          onChange={onChange}
        />
      </Form.Group>
    </>
  );
};
